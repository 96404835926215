import '../css/tailwind.css'

import { createApp } from 'vue/dist/vue.esm-bundler';
import MobileMenu from "./components/landing/MobileMenu.vue";

const app = createApp({
    data() {
        return {
            isMobileMenuOpen: true
        }
    },

    methods: {
        openMobileMenu() {
            this.isMobileMenuOpen = true
        },

        closeMobileMenu() {
            this.isMobileMenuOpen = false
        }
    }
})

app.config.devtools = true
app.component('MobileMenu', MobileMenu)

app.mount('#app')


